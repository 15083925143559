.Introduction {
  .relations-container {
    svg {
      max-height: 5.5em;
    }
  }

  .capacity-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label-container {
      width: 90%;
    }

    .slider-container {
      position: relative;
      padding-bottom: 2em;
      width: 80%;

      input {
        width: 100%;
      }

      .tick-labels {
        display: flex;
        flex-direction: row;
        height: 2em;

        p {
          transform: translateX(-50%);
          width: fit-content;

          &:first-child {
            position: absolute;
            left: 20%;
          }

          &:nth-child(2) {
            position: absolute;
            left: 60%;
          }

          &:nth-child(3) {
            position: absolute;
            left: 100%;
          }
        }
      }
    }
  }
}
