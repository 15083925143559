@import "variables";
@import "App";
@import "components/Introduction";
@import "components/FarmsCountStackedAreaChart";
@import "components/QuizPage";
@import "components/DraggableBarChart";
@import "components/FarmsCountBarChart";
@import "components/Footer";
@import "components/AnimatedRelations";
@import "components/RelativeLineChart";
@import "components/GrowPossibilities";

body {
  font-family: Avenir, "Helvetica Neue", Helvetica, sans-serif;
  background-color: #ebebe5;

  h1 {
    font-size: 3em;
    font-weight: bold;
    margin: 0 0 1em;
  }

  h2 {
    font-size: 2em;
    margin: 2em 0 1em;
  }

  h3 {
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.4em 0;
  }

  p {
    max-width: 90%;
    margin: 0 auto 1em;
  }

  .horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0.2em auto;

    & > * {
      width: 33%;
    }

    &.larger > * {
      width: 40%;
    }

    p {
      margin-bottom: 0.5em;
    }

    &.top {
      width: 85%;
      align-items: baseline;
    }

    &.bottom {
      width: 85%;
    }

    &.centered {
      align-items: center;
    }
  }

  .color-0-1 {
    color: #c2eedc;
  }

  .color1-3 {
    color: #7fd1af;
  }

  .color3-5, .color-small {
    color: #1cb373;
  }

  .color5-10 {
    color: #168c5a;
  }

  .color10-20 {
    color: #66bbff;
  }

  .color20-30, .color-medium {
    color: #1e8cd3;
  }

  .color30-50 {
    color: #ebb0dd;
  }

  .color50-plus, .color-large {
    color: #d674c0;
  }

  input {
    &[type=range]::-webkit-slider-runnable-track {
      height: 4px;
      background: $clr_background;
    }

    &[type=range]:focus::-webkit-slider-runnable-track {
      background: $clr_background;
    }

    &[type=range]::-moz-range-track {
      height: 4px;
      background: $clr_background;
    }

    &[type=range]::-webkit-slider-thumb {
      height: 18px;
      width: 18px;
      background: $clr_background;
      margin-top: -6px;
    }

    &[type=range]::-moz-range-thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #fff;
    }
  }

  svg {
    text {
      font-family: Avenir, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 13px;

      &.header {
        font-weight: bold;
        font-size: 18px;
        fill: currentColor;
      }

      &.header-small {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bolder;
      }

      .legend {
        text {
          font-size: 14px;
        }
      }
    }
  }
}
