.GrowPossibilities {
  .relations-container {
    .cow {
      img {
        padding: 0.2em;
        margin: 0.5em;
        height: 50px;
      }
    }

    .wheat {
      img {
        margin: 0.5em 0.3em;
        height: 50px;
      }
    }

    .cow, .wheat {
      margin: 0.5em 0 1em;
    }
  }
}
