.App {
  max-width: 840px;
  text-align: center;
  margin: auto;

  & > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      background-color: white;
      border-radius: 1em;
      min-height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
