.QuizPage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #dadad2;
  align-self: center;
  min-width: 840px;

  .quizButton {
    width: 50%;
    height: 8em;
    transform: translateY(8em);
  }

  .toggle-container {
    padding: 0 0 2em;
    min-height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      height: 42px;
    }

    p {
      margin: 0;
    }

    .show {
      display: flex;
      transition: 400ms ease-in;
    }

    .hide {
      display: none;
      transition: 200ms ease-out;
    }
  }

  .fade-in {
    animation: fade-in 6s ease-in;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
