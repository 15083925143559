.FarmsCountBarChart {

  .chartContainer {
    position: relative;

    .inline-label {
      position: absolute;
      right: 120px;
      top: 50px;
      text-transform: unset;
    }
  }

  .sliderContainer {
    padding-top: 2em;
    margin: auto;
    width: 85%;

    .slider {
      width: 100%;
    }

    ul {
      width: 100%;
      padding: 0 30px 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;

      li {
        text-align: center;
        transform: translateX(-50%);
      }
    }
  }
}