.DraggableBarChart {

  svg {
    background-color: white;
  }

  .overlay, .selection, .handle--s {
    cursor: unset;
  }

  text, .handle-bar {
    pointer-events: none;
    font-size: 16px;
  }

  .handle--n {
      cursor: grab;
      transform: translate(0,-15px);
    }

  .bar {
    .on-hover-only {
      opacity: 0;
    }

    &:hover {
      .on-hover-only {
        opacity: 1;
      }
    }
  }

  .showAnswer {
    * {
      pointer-events: none;
    }
  }
}
